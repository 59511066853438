import { graphql } from "gatsby"
import React from "react"

import ProcessFlow from "./ProcessFlow"

export const fragment = graphql`
  fragment ProcessFlowFragment on WpPage_Flexlayouts_FlexibleLayouts_ProcessFlow {
    steps {
      step
    }
    config {       
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFProcessFlow = ({ steps, config }) => <ProcessFlow steps={steps} config={config} />
