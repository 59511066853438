import React from "react"
import { Row, Col } from "react-bootstrap"
import { Section } from "../../UI/Common"
import RightArrow from "./assets/icons-arrow-steps.inline.svg"
import {
  stepClass,
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  arrowPosition,
} from "./ProcessFlow.module.scss"


const ProcessFlow = ({ steps, config }) => {
  const { padding } = config || {}

  const styles = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
  ]
  return (
    <Section padding={padding}>
      <Row className="d-flex align-items-md-center">
        {steps && steps.map((step, i) => {
          return (
            <Col xs="12" md="auto" key={i} className={stepClass}>
              <div className="position-relative">
                <div className={`${styles[i]}`}>{step?.step}</div>
              </div>
              {i !== styles.length - 1 && (
                <RightArrow className={arrowPosition} />
              )}
            </Col>
          )
        })}
      </Row>
    </Section>
  )
}

export default ProcessFlow
