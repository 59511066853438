// extracted by mini-css-extract-plugin
export var arrowPosition = "ProcessFlow-module--arrowPosition--e5a7e";
export var backgroundImage1 = "ProcessFlow-module--backgroundImage1--a0e3d";
export var backgroundImage2 = "ProcessFlow-module--backgroundImage2--96ea0";
export var backgroundImage3 = "ProcessFlow-module--backgroundImage3--034f6";
export var backgroundImage4 = "ProcessFlow-module--backgroundImage4--120d8";
export var backgroundImage5 = "ProcessFlow-module--backgroundImage5--16569";
export var bgGrey100 = "ProcessFlow-module--bg-grey-100--d6761";
export var bgGrey150 = "ProcessFlow-module--bg-grey-150--6ee2e";
export var bgGrey200 = "ProcessFlow-module--bg-grey-200--8a6cc";
export var bgGrey300 = "ProcessFlow-module--bg-grey-300--c422e";
export var bgGrey400 = "ProcessFlow-module--bg-grey-400--f3c7e";
export var bgGrey500 = "ProcessFlow-module--bg-grey-500--f678d";
export var bgGrey600 = "ProcessFlow-module--bg-grey-600--f7d5b";
export var bgGrey700 = "ProcessFlow-module--bg-grey-700--ae8fa";
export var bgGrey800 = "ProcessFlow-module--bg-grey-800--dd909";
export var bgGrey900 = "ProcessFlow-module--bg-grey-900--48bd4";
export var stepClass = "ProcessFlow-module--stepClass--4e865";
export var textGrey100 = "ProcessFlow-module--text-grey-100--32a76";
export var textGrey150 = "ProcessFlow-module--text-grey-150--44736";
export var textGrey200 = "ProcessFlow-module--text-grey-200--c18b2";
export var textGrey300 = "ProcessFlow-module--text-grey-300--4a8b8";
export var textGrey400 = "ProcessFlow-module--text-grey-400--eda9f";
export var textGrey500 = "ProcessFlow-module--text-grey-500--b52bf";
export var textGrey600 = "ProcessFlow-module--text-grey-600--6c372";
export var textGrey700 = "ProcessFlow-module--text-grey-700--88894";
export var textGrey800 = "ProcessFlow-module--text-grey-800--32fb3";
export var textGrey900 = "ProcessFlow-module--text-grey-900--9abae";